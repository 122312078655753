<template>
  <ul class="tab-nav">
    <li
      v-for="item in data"
      :key="item.url"
      :class="{
        'on': item.url === active
      }"
      @click="onItemClick(item)">
      {{item.name}}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'tabNav',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    active: {
      type: String,
      default: ''
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.onRouteChange()
      }
    }
  },
  methods: {
    onItemClick(item) {
      if (this.$route.path === item.url.split('?')[0]) {
        return
      }
      this.$emit('update:active', item.url)
      this.$router.push(item.url)
    },
    onRouteChange() {
      let path = this.$route.path
      // 优先当前路由（如：直接带过来详情url）
      let tab = this.data.find(item => item.url.split('?')[0] === path)
      if (tab) {
        this.$emit('update:active', tab.url)
        return
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .tab-nav {
    margin-bottom: 20px;
    li {
      float: left;
      margin-right: 8px;
      padding: 12px 20px;
      background-color: #eee;
      transition: all .2s ease;
      cursor: pointer;
      color: #606266;
      font-size: 14px;
      border: 1px solid #eee;
      &.on, &:hover {
        background-color: #409EFF;
        color: #fff;
      }
    }
    &::after {
      content: '';
      display: block;
      height: 0;
      width: 0;
      clear: both;
    }
  }
</style>
