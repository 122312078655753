<template>
  <div class="m-pg-sub">
    <page-cover code="4" layer="rgba(0,0,0,.3)">
      <!-- <h2 class="m-page-title">{{$t('page.aboutus.name')}}</h2> -->
      <!-- <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.aboutus.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <tab-nav
        :data="tabNavData"
        :active.sync="tabNavActiveUrl">
      </tab-nav>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/tab-nav'
export default {
  components: {
    TabNav
  },
  data() {
    return {
      tabNavData: [
        {name: this.$t('page.aboutus.m_tab.intro'), url: '/about-us/intro'},
        {name: this.$t('page.aboutus.m_tab.concat'), url: '/about-us/concat'}
      ],
      tabNavActiveUrl: '/about-us/intro'
    }
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    .side-menu {
      box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
      border-right: none;
    }
    .content {
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }
  }
</style>
